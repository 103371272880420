import * as React from 'react';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import DataAnalytics from '../../modules/serviceinfo/DataAnalytics';

const IndexPage = () => {
  return (
    <Layout>
      <DataAnalytics />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
