import * as React from 'react';

const DataAnalytics = () => {
  return (
    <div className=" mt-32 max-w-5xl mx-auto relative leading-relaxed">
      <h1 className="font-display text-5xl leading-normal font-semibold">
        Data and Analytics in Software Development with Zegates
      </h1>
      <div className="w-full">
        <div className="border-default border-2 inline-block rounded-full px-8 bg-lightgreen my-4 py-1 font-semibold">
          Zegates Services
        </div>
      </div>

      <div className="grid grid-cols-2 my-24 gap-4 ">
        <img
          src="/images/services/data-analytics.jpg"
          className=" rounded-2xl"
        />

        <div className="flex items-center justify-center">
          <p className="max-w-96">
            In today’s rapidly evolving technological landscape, data and
            analytics have become indispensable tools in software development.
            By leveraging vast amounts of data, developers and organizations can
            enhance the efficiency, quality, and user satisfaction of their
            software products. This article explores the multifaceted role of
            data and analytics in software development, highlighting their
            impact on decision-making, performance optimization, and user
            experience.
          </p>
        </div>
      </div>
      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        Informed Decision-Making
      </h1>

      <div className="grid grid-cols-2 mt-8 gap-4">
        <div>
          <p className="mb-8 max-w-96">
            Data and analytics provide critical insights that guide
            decision-making throughout the software development lifecycle. By
            analyzing historical data, development teams can identify trends,
            patterns, and anomalies that inform strategic decisions.
          </p>
          <img src="/images/services/da-user.jpg" className=" rounded-2xl" />
        </div>

        <div className="">
          <h3 className="text-lg my-4 font-bold">Requirements Analysis: </h3>

          <p className="mb-4">
            Data-driven analysis helps in understanding user needs and market
            demands. By collecting and analyzing user feedback, market trends,
            and competitor analysis, developers can prioritize features that
            offer the most value.
          </p>

          <h3 className="text-lg my-2 font-bold">Project Planning: </h3>

          <p className="mb-4">
            Historical data on previous projects helps in estimating timelines,
            budgets, and resource allocation more accurately. Predictive
            analytics can forecast potential risks and bottlenecks, allowing
            teams to mitigate issues proactively.
          </p>
        </div>
      </div>

      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        Performance Optimization
      </h1>

      <div className="grid grid-cols-2 mt-8 gap-12 mb-4">
        <div className="">
          <h3 className="text-lg my-4 font-bold">Requirements Analysis: </h3>

          <p className="mb-4">
            Data-driven analysis helps in understanding user needs and market
            demands. By collecting and analyzing user feedback, market trends,
            and competitor analysis, developers can prioritize features that
            offer the most value.
          </p>

          <h3 className="text-lg my-2 font-bold">Project Planning: </h3>

          <p className="mb-4">
            Historical data on previous projects helps in estimating timelines,
            budgets, and resource allocation more accurately. Predictive
            analytics can forecast potential risks and bottlenecks, allowing
            teams to mitigate issues proactively.
          </p>
        </div>
        <div>
          <p className="mb-8 max-w-96">
            Data and analytics provide critical insights that guide
            decision-making throughout the software development lifecycle. By
            analyzing historical data, development teams can identify trends,
            patterns, and anomalies that inform strategic decisions.
          </p>
          <img src="/images/services/da-perf.jpg" className=" rounded-2xl" />
        </div>
      </div>
    </div>
  );
};

export default DataAnalytics;
